.link {
    color: #2c7be5;
}

.link:focus,
.link:hover {
    color: #2c7be5;
    text-decoration: none;
}

/* For WebKit browsers (Chrome, Safari) */
.scrollable {
    width: 100%;
    height: 90%;
    overflow: auto;
  }
  
  /* Custom scrollbar for WebKit */
  .scrollable::-webkit-scrollbar {
    width: 3px; /* Adjust the width here */
  }
  
  .scrollable::-webkit-scrollbar-thumb {
    background-color: darkgrey; /* Color of the scrollbar */
    border-radius: 10px; /* Rounded corners */
  }
  
  .scrollable::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
  }
  