/* styles.css (or your preferred CSS file) */

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .rotate {
    animation: spin 2s linear infinite; /* Adjust the duration and other properties as needed */
  }
  

  /* styles.css (or your preferred CSS file) */

@keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.4);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .pulse {
    animation: pulse 2s infinite; /* Adjust the duration and other properties as needed */
  }
  