.multiselect {
  z-index: 1000 !important;
  background-color: white !important;
  color: black !important;
}

.multiselectDark {
  z-index: 1000 !important;
  background-color: #0b1727 !important;
  --rmsc-main: teal !important;
  --rmsc-hover: #0d2545 !important;
  --rmsc-selected: #2c7be5 !important;
  --rmsc-border: #425466 !important;
  --rmsc-gray: white !important;
  --rmsc-bg: #0b1727 !important;
  --rmsc-p: 10px !important;
  --rmsc-radius: 4px !important;
  --rmsc-h: 38px !important;
  color: white !important;
}

.disabledMultiselect {
  z-index: 0 !important;
}

.darkDisabledMultiselect {
  z-index: 0 !important;
  --rmsc-bg: #2d3748 !important;
  --rmsc-border: #425466 !important;
}