.gradient-background {
    background: linear-gradient(135deg, blue, white);
    color: whitesmoke
}

.custom-button {
    background: linear-gradient(135deg, rgb(126, 126, 13), #d37714, #fc4a33);
    color: whitesmoke;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    margin-top: 20px;
}