:root { 
  --bg-color: rgb(30, 99, 254);
  --border-color: rgb(30, 99, 254);
}

.driver-popover.driverjs-theme {
    background-color: #fff;
    color: #fff;
  }
  
  .driver-popover.driverjs-theme .driver-popover-title {
    font-size: 20px;
    margin: 20px 5px 0 5px;
    font-family: Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    letter-spacing: 0.5px;
    font-weight: 600;
  }
  
  .driver-popover.driverjs-theme,
  .driver-popover.driverjs-theme .driver-popover-description,
  .driver-popover.driverjs-theme .driver-popover-progress-text {
    color: #000;
    margin: 0 5px;
  }

  .driver-popover.driverjs-theme .driver-popover-progress-text { 
    font-weight: 600;
  }
  
  .driver-popover.driverjs-theme button {
    flex: 1;
    text-align: center;
    background-color: var(--bg-color);
    color: #ffffff;
    text-shadow: none;
    font-size: 12px;
    padding: 6px 8px;
    box-shadow: none;
    outline: none;
    border: none; 
  }
  
  .driver-popover.driverjs-theme .driver-popover-navigation-btns {
    justify-content: space-between;
    gap: 3px;
  }
  
  .driver-popover.driverjs-theme .driver-popover-close-btn {
    color: #000;
    font-size: 1.5rem;
    background: none;
  }
  
  .driver-popover.driverjs-theme .driver-popover-close-btn:hover {
    color: var(--bg-color);
  }
  
  .driver-popover.driverjs-theme .driver-popover-arrow-side-left.driver-popover-arrow {
    border-left-color: var(--border-color);
  }
  
  .driver-popover.driverjs-theme .driver-popover-arrow-side-right.driver-popover-arrow {
    border-right-color: var(--border-color);
  }
  
  .driver-popover.driverjs-theme .driver-popover-arrow-side-top.driver-popover-arrow {
    border-top-color: var(--border-color);
  }
  
  .driver-popover.driverjs-theme .driver-popover-arrow-side-bottom.driver-popover-arrow {
    border-bottom-color: var(--border-color);
  }

  .guide-description { 
    font-size: 13px;
    margin-top: 4px;
  }