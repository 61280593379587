.mainEditBoxSection {
    min-height: 100px;
    width: 100%;
    padding: 1%;
    margin-bottom: 20px;
}

.mainEditBoxSection textarea {
    min-height: 100px;
    width: 100%;
    margin-top: 5px;
    resize: none;
    border-radius: 5px;
    padding: 1%;
    border: none;
    /* color: #f5f5f59a; */
}

.mainEditBoxSection .btnBox {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.mainEditBoxSection .btnBox button {
    height: 40px;
    width: fit-content;
    padding: 1% 2%;
    background-color: whitesmoke;
    color: grey;
    border-radius: 5px;
    transition: transform .2s;
    cursor: pointer;
}

.mainEditBoxSection .btnBox button:active {
    opacity:0;
}