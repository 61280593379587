
@media print {
    /* Hide elements you don't want to print */
    .no-print {
      display: none;
    }
  
    /* Ensure the body takes the full width */
    body {
      margin: 0;
      padding: 0;
      width: 100%;
    }
  
    /* Adjust specific elements */
    .printable-area {
      max-width: 100%; /* Ensure this area takes the full width */
      overflow: visible; /* Prevent overflow */
    }
  
    /* Optionally, adjust font size, colors, etc. */
    h1, h2, h3, p {
      color: black; /* Print in black */
    }

    .navbar-vertical {
        display: none;  /* Hide elements not needed in print */
      }

      main {
        width: 100%;
      }
      
  }

  @page {
    size: auto; /* auto is the initial size */
    margin: 0mm; /* Adjust margins as needed */
  }
  