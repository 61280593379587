.swiper-button-next::after,
.swiper-button-prev::after {
  content: "";
  display: none
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}

.swiper-container {
  height: 100%;
  max-height: 100vw;
  min-width: 0;
  max-width: 100vw;
  width: 100%;
  overflow: hidden;
}

.swiper-slide {
  width: 100%;
  /* flex-shrink: 0; */
  flex-shrink: 100 !important;
  display: block;
  height: 100%;
  max-height: 100%;
}

.talk-bubble {
  margin: 40px;
  display: inline-block;
  position: relative;
  width: 100%;
  height: auto;
  background-color: blue;
}

.triangle.left-top:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: -20px;
  right: auto;
  top: 0px;
  bottom: auto;
  border: 22px solid;
  border-color: blue transparent transparent transparent;
}

.triangle.right-top:before {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -20px;
  top: 0;
  bottom: auto;
  border: 32px solid;
  border-color: blue transparent transparent transparent;
}

.talktext {
  color: white;

  padding: 1em;
}

.videoIcon:active {
  opacity: 0;
}

.picker-dialog-bg {
  z-index: 20000 !important;
}

.picker-dialog {
  z-index: 20001 !important;
}
.parent_constraint {
}
.parent_constraint img{
  max-width: 100%;
  height: auto;
}

.form-control-danger-border:focus {
  border-color: orange !important;
}

/* .zoom-in-out {
  transform: scale(1.2); 
} */

.zoom-in-out {
  transform: scale(1.4); 
}