.force-refresh-postly-logo {
  display: block;
}

#loader {
  height: 100px;
  width: 20%;
  text-align: center;
  padding: 1em;
  align-items: center;
  justify-items: center;
  place-content: center;
  margin: auto auto;
  display: grid;
}

.loading-div {
  position: absolute;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(1px);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

#loader svg path,
svg rect {
  fill: #2c7be5;
  position: absolute;
}

@media screen and (max-width: 1189px) {
  .force-refresh-postly-logo {
    display: none !important;
  }
}
