.chat-message-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.avatar-and-title {
  display: flex;
  align-items: center;
  justify-items: center;
  /* margin-bottom: -10px; */
  /* background-color: red; */
}

.avatar-and-title>* {
  margin-right: 8px;
  margin-top: 1px;
}

.chat-message {
  padding: 12px;
  border-radius: 20px;
  overflow-wrap: break-word;
  font-size: 14px;
  min-width: 100%;
  /* background-color: #f8f9fa; */
  /* color: #000; */
  white-space: pre-wrap;
}

.title {
  /* margin-top: -10px; */
}

.btn-custom {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}

.btn-custom svg {
  width: 15px;
  height: auto;
  transition: transform 0.3s ease;
  margin-right: 10px;
}

.btn-custom svg:hover  {
  transform: scale(1.2);
}
.btn-custom svg.clicked  {
  /* transform: scale(1.5); */
}

.btn-custom.hovered {
  /* background-color: #2c7be5;
  /* color: #fff !important; */
}

.btn-custom.clicked {
  /* background-color: #2c7be5; */
  /* color: #fff; */
  /* transform: scale(0.5); */
}

.message-options {
  position: relative;
  bottom: 2px;
  justify-content: space-between;
  font-size: small;
  display: inline-flex;
  margin-bottom: 10px;
}

.option-icon {
  margin-left: 10px;
  cursor: pointer;

}

.tooltip {
  visibility: hidden;
  background-color: black;
  color: white;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  opacity: 100;
  bottom: 125%;
  left: 50%;
  margin-left: -30px;
}

.btn-custom:hover .tooltip {
  visibility: visible;
}