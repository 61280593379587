.custom-select-container {
    position: relative;
    display: inline-block;
    width: 200px;
    height: 10vh;
  }
  .custom-select {
    position: relative;
    padding: 10px;
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 500;
    color: #333;
    border-width: 0px;
    border-style: solid;
    border-color: rgb(227, 227, 227);
    border-image: initial;
    border-radius: 5px;
    background-color: #f9f9f9;
    cursor: pointer;
  }
  
  .custom-select-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
  
  .custom-select-options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    width: 25vw;
    z-index: 1;
    max-height: 180px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
  }
  
  
  .custom-select-option {
    padding: 10px;
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 500;
    color: #333;
    cursor: pointer;
  }
  
  .custom-select-option:hover {
    background-color: #f0f0f0;
  }
  
.chat-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 72vh;
    margin-left: 4vw;
    border-radius: 8px;
    overflow: hidden;
}

.chat-messages {
    overflow-y: auto;
    padding: 10px;
}
.chat-input {
    display: flex;
    align-items: center;
    padding: 10px;
    border-top: none; 
    margin-right: 1rem;
  }
  
  .chat-input .form-control {
    flex: 1; 
    border-radius: 10px; 
    border: 1px solid darkgrey;
    padding: 12px 20px; 
    font-size: 14px;
    outline: none;
    box-shadow: none;
  }
  
  .send-button {
    background-color: #ccc; 
    color: #fff; 
    border: none; 
    border-radius: 10px;
    padding: 6px;
    margin-left: -40px; 
    transition: background-color 0.3s ease; /* Smooth transition for background color change */
  }
  
  .send-button.has-text {
    background-color: black; 
  }
  #modalExport .modal-content{
    max-width: 300px;
  }
  .send-button:focus {
    outline: none; 
  }
  
  .send-button > svg {
    width: 17px;
    height: 15px; 
    vertical-align: middle; 
  }
  .sidenav {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 17vw;
    background-color: rgb(248, 249, 250);
    box-shadow: #333;
    z-index: 1;
    padding-top: 20px;
    transition: transform 0.3s ease;
    transform: translateX(-100%);
  }
  
  .sidenav .open {
    transform: translateX(0);
  }
  
  .sidenav-title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .conversation-list {
    list-style: none;
    padding: 0;
  }
  
  .conversation-item {
    margin-bottom: 10px;
  }
  
  .conversation-item button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: transparent;
    border: none;
    text-align: left;
    cursor: pointer;
  }
  
  .conversation-item button:hover {
    background-color: #e9ecef;
  }
  .aiconversation-list {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 17vw;
    margin-bottom: -0.1vh;
    font-size: .75rem;
    line-height: 1rem;
    font-weight: 600;
    background-color: #fff;
    padding: 50px 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    list-style-type: none;
  }
  
  .aiconversation-list li {
    
    margin-bottom: 15px;
  }
  
  .aiconversation-list button {
    background-color: #2c7be5;
    color: #fff;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 10px;
  }
  
  .aiconversation-list button:hover {
    background-color: #2c7be5;
  }
  
  .aiconversation-list a {
    text-decoration: none;
    color: inherit;
  }
  
  .aiconversation-list b {
    color: #2c7be5;
    font-weight: bold;
    margin-left: 10px;
  }
  
  .new-chat-button {
    /* display: inline-flex; */
    align-items: center;
    justify-content: center;
    /* padding: 8px 16px; */
    background-color: #2c7be5;
    margin-bottom: 20px;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 6px;
    width: 75%;
    height: 6vh;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  }
  
  .new-chat-button:hover {
    background-color: rgba(255, 255, 255, 0.2); 
    color: #000;
  }
  .menu-icon {
    /* display: inline-flex; */
    align-items: center;
    justify-content: center;
    background-color: grey;
    align-items: center;
    top: 20px;
    justify-content: center;
    border: none;
    cursor: pointer;
    color:white;
    width:15%;
    height: 6vh;
    border: 2px solid #fff;
    border-radius: 6px;
    margin-bottom: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  }
  
  .menu-icon svg {
    transition: color 0.3s ease;
  }
  
  .menu-icon:hover svg {
    color: #333; 
  }
  .icon {
    width: 20px;
    height: 20px;
    margin-right: 8px; 
    flex-shrink: 0;
  }
  
  .icon path {
    stroke: #fff;
  }
  
  .new-chat-button:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  /* Styles for the dropdown container */
.menu-container {
  position: absolute;
  right: 0;
  width: 80%;
  top: 4.5rem; 
  z-index: 100;
  background-color: #e9ecef;
  border-radius: 0.375rem;
  box-shadow: 0px 4px 6px rgba(38, 148, 212, 0.1);
  padding: 0.5rem;
}

.menu-item {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0.5rem 2vw;
  /* padding-right: 3.5vw; */
  /* font-size: 0.675rem; */
  color: black;
  /* background-color: #2c7be5; */
  margin-bottom: 2vh;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0.375rem;
  border: 0;
  box-shadow: 0;
}

.menu-item:hover {
  background-color: white;
  color: #000;
  box-shadow: 10;
}

.menu-item-icon {
  margin-right: 0.75rem;
  height: 1.25rem;
  width: 1.25rem;
}
/* .menu-item .user-profile-icon {
  color: white;
} */

/* .menu-item .arrow-icon {
  color: white;
} */

.menu-item .backup-icon {
  height: 1.25rem;
  width: 1.25rem;
}

.intro-message {
  padding: 20px;
}

.font-semibold {
  font-weight: 500;
}

.text-lg {
  font-size: 1.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.chat-options-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.chat-option-btn {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 10px;
  background-color: #f5f7fa;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.chat-option-btn:hover {
  background-color: #e4e7eb;
}

.chat-option-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.opacity-50 {
  opacity: 0.5;
}

.modal-content {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.modal-header {
  border-bottom: none;
  padding-bottom: 0;
}

.modal-title {
  font-weight: bold;
  font-size: 1.5rem;
}

.modal-body {
  padding: 20px;
}

.modal-footer {
  border-top: none;
  padding-top: 0;
}

.api-key-input {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.api-key-input p {
  /* margin-right: 25px; */
  font-weight: bold;
  width: 100px;
}

.api-key-input input {
  flex: 1;
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  outline: none;
}

.api-key-input button {
  padding: 5px 10px !important;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.api-key-input button:hover {
  background-color: #0056b3; 
}


.typingmind-modal-body {
  background-color: #ffffff;
  border-radius: 0.5rem;
  padding: 1rem;
}

.typingmind-modal-title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333333;
}

.typingmind-modal-description {
  text-align: center;
  color: #666666;
}

.typingmind-modal-select {
  width: 100%;
  padding: 0.5rem;
  margin-top: 1rem;
  border: 1px solid #cccccc;
  border-radius: 0.25rem;
}

.typingmind-modal-tabs {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 27vw; 
}

.typingmind-modal-tab {
  padding: 10px;
  cursor: pointer;
  flex: 1;
  white-space: nowrap;
}

.tab-highlighter {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background-color: #2c7be5; 
  transition: transform 0.3s ease; 
}
.tab-count {
  background-color: white;
  color: #2c7be5;
  padding: 4px 8px;
  border-radius: 4px;
  margin-left: 8px; /* Adjust as needed */
}

.typingmind-modal-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.typingmind-modal-input {
  flex-grow: 1;
  padding: 0.5rem;
  border: 1px solid #cccccc;
  border-radius: 0.25rem;
}

.typingmind-modal-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  border: none;
  cursor: pointer;
}

.typingmind-modal-icon {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.5rem;
  color: #2c7be5;
  text-decoration-color: #2c7be5;
}

.typingmind-modal-tag-input {
  margin-top: 1rem;
}

.typingmind-modal-tag-input-text {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #cccccc;
  border-radius: 0.25rem;
}

.typingmind-modal-no-prompts {
  text-align: center;
  color: #666666;
  margin-top: 1rem;
}

.typingmind-modal-divider {
  margin-top: 1rem;
  border: none;
  border-top: 1px solid #cccccc;
}

.typingmind-modal-close {
  text-align: center;
  margin-top: 1rem;
}

.typingmind-modal-close-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  background-color: #007bff;
  color: #ffffff;
  cursor: pointer;
}

.typingmind-prompt-list {
  margin-top: 20px;
}

.typingmind-prompt-item {
  background-color: #F7F7F7;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #333333;
}
.typingmind-use-button,
.typingmind-add-button {
  margin-left: 10px;
  padding: 5px 10px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  background-color: #007BFF;
  color: #FFFFFF;
  cursor: pointer;
}

.typingmind-use-button:hover,
.typingmind-add-button:hover {
  background-color: #0056b3;
}


/* Button Styles */
.inline-flex {
  display: inline-flex;
}

.items-center {
  align-items: center;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.border {
  border-width: 1px;
}

.rounded-md {
  border-radius: 0.375rem;
}

.shadow-sm {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.text-white {
  color: #ffffff;
}

.bg-blue-600 {
  background-color: #2563eb;
}

.hover\\:bg-blue-700:hover {
  background-color: #3b82f6;
}

.focus\\:ring-blue-500:focus {
  outline-color: #3b82f6;
}

.bg-green-600 {
  background-color: #10b981;
}

.hover\\:bg-green-700:hover {
  background-color: #3bbf83;
}

.focus\\:ring-green-500:focus {
  outline-color: #3bbf83;
}
.flex.items-center.gap-2 {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}